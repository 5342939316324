import sanitizeHTML from "sanitize-html"

export const SanitizeHtml = (text: string) => {
  return sanitizeHTML(text, {
    allowedAttributes: {
      span: ["style"],
      div: ["style"],
    },
  })
}
